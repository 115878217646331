export const state = {
  uid: undefined,
  email: undefined,
  password: undefined,
}

export const mutations = {
  SET (state, data) {
    state.uid = parseInt(data.uid)
    state.email = data.email
    state.password = data.password
  },
  RESET (state) {
    Object.keys(state).forEach(key => {
      state[key] = undefined
    })
  }
}
