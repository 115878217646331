import miniToastr from 'mini-toastr'

miniToastr.init({
  types: {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn'
  }
})

miniToastr.setIcon('success', 'i', { 'class': 'fas fa-check-circle' })
miniToastr.setIcon('error', 'i', { 'class': 'fas fa-exclamation-triangle' })
miniToastr.setIcon('info', 'i', { 'class': 'fa fa-info-circle' })
miniToastr.setIcon('warn', 'i', { 'class': 'fa fa-spinner fa-spin' })
function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb)
}

export default {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
}
