import vue from 'vue'

export const state = {
  // ID игрового шаблона
  gid: undefined,
  // Название игрового шаблона
  gname: undefined,
  // ID игрового сервера
  ugid: undefined,
  // Steam ID игры
  stid: undefined,
  // IP игрового сервера
  ip: undefined,
  // Порт
  port: undefined,
  // Порт запроса
  port_query: undefined,
  // RCON порт
  port_rcon: undefined,
  // RCON пароль
  password_rcon: undefined,
  // Признак "процесс запущен"
  is_running: undefined,
  // Признак "услуга приостановлена"
  is_suspended: undefined,
  // Признак "сервер заблокирован"
  is_locked: undefined,
  // Признак "сервер на техническом обслуживании"
  is_maintenance: undefined,
  // Признак "машина офлайн"
  is_machine_offline: undefined,
  // Признак "Перед стартом"
  is_before_start: undefined,
  // Описание "Перед стартом"
  before_start_message: undefined,
  // Признак "владелец машины"
  is_machine_owner: undefined,
  widget_1: undefined,
  widget_2: undefined,
  widget_3: undefined,
  widget_4: undefined,
  database_allowed: undefined,
  umod: undefined,
  console_parsing: undefined,
  update_daemon: undefined,
  daemon_update_log_wss: undefined,
  comment: undefined,
  menu_settings: undefined,
  menu_statistic: undefined,
  menu_addons: undefined,
  menu_updates: undefined,
  menu_utilities: undefined,
  menu_console: undefined,
  menu_chat: undefined,
  menu_players: undefined,
  menu_filelog: undefined,
  menu_banlist: undefined,
  menu_ftp: undefined,
  // Информация от игрового сервера
  query: {
    // Состояние ARK сервера
    ark_state: undefined,
    // Признак "сервер онлайн"
    is_online: undefined,
    // Название сервера
    name: undefined,
    // Название карты
    map: undefined,
    // FPS всего (лимит)
    fps_total: undefined,
    // FPS текущий
    fps_current: undefined,
    // CPU
    cpu: undefined,
    // RAM
    ram: undefined,
    // Количество сущностей (RUST)
    entity: undefined,
    // Network in (RUST)
    network_in: undefined,
    // Network out (RUST)
    network_out: undefined,
    // Uptime (Rust)
    uptime: undefined,
    // Игроки
    players: {
      // Всего (лимит)
      total: undefined,
      // Активные
      active: undefined,
      // в очереди
      queue: undefined,
      // Список игроков
      list: undefined
    }
  }
}

/**
 * Модуль хранит только значения,
 * которые можно получить из панели,
 * сокеты контролируются в "Components/GameServers/Management/Control"
 */
export const mutations = {
  /**
   * Метод устанавливает значения хранилища
   * @param state хранилище
   * @param data данные игрового сервера
   */
  SET: (state, data) => {
    // Проверяем первые ключи объёктов
    Object.keys(state).forEach(stateKey => {
      Object.keys(data).forEach(dataKey => {
        // Если ключ объекта хранилища совпадает с ключом данных
        if (stateKey === dataKey) {
          // Если значение в ключе объект
          if (
              (typeof state[stateKey] !== 'undefined' && state[stateKey] instanceof Object) &&
              (typeof data[dataKey] !== 'undefined' && data[dataKey] instanceof Object)
          ) {
            // Проверяем вложенные ключи объектов первого уровня
            Object.keys(state[stateKey]).forEach(stateKeyNested1 => {
              Object.keys(data[dataKey]).forEach(dataKeyNested1 => {
                // Если ключ объекта хранилища совпадает с ключом данных
                if (stateKeyNested1 === dataKeyNested1) {
                  // Если значение в ключе объект
                  if (
                      (
                          typeof state[stateKey][stateKeyNested1] !== 'undefined' &&
                          state[stateKey][stateKeyNested1] instanceof Object
                      ) &&
                      (
                          typeof data[dataKey][dataKeyNested1] !== 'undefined' &&
                          data[dataKey][dataKeyNested1] instanceof Object
                      )
                  ) {
                    // Проверяем вложенные ключи объектов второго уровня
                    Object.keys(state[stateKey][stateKeyNested1]).forEach(stateKeyNested2 => {
                      Object.keys(data[dataKey][dataKeyNested1]).forEach(dataKeyNested2 => {
                        // Если ключ объекта хранилища совпадает с ключом данных
                        if (stateKeyNested2 === dataKeyNested2) {
                          // Устанавливаем значение ключа хранилища
                          if (
                              (state.stid === 258550 &&
                                  ['active', 'total'].indexOf(stateKeyNested2) !== -1) ||
                              stateKeyNested2 === 'list'
                          ) return
                          vue.set(
                              state[stateKey][stateKeyNested1],
                              stateKeyNested2,
                              data[dataKey][dataKeyNested1][dataKeyNested2] !== null
                                  ? data[dataKey][dataKeyNested1][dataKeyNested2]
                                  : undefined
                          )
                        }
                      })
                    })
                  } else {
                    // Еслит это RUST и сервер остановлен или только запускается
                    if (
                        state.stid === 258550 &&
                        (!data['is_running'] || typeof data['query'] !== 'undefined' && !data['query']['is_online'])) {
                      // Если значения счётчиков
                      if (['fps_total', 'fps_current', 'entity', 'network_in', 'network_out', 'uptime'].indexOf(stateKeyNested1) !== -1) {
                        // Обнуляем значения
                        vue.set(state[stateKey], stateKeyNested1, 0)
                      }
                    }
                    // Если это RUST  и сервер запущен
                    if (
                        state.stid === 258550 &&
                        (data['is_running'] || typeof data['query'] !== 'undefined' && data['query']['is_online'])) {
                      // Если это не значения счётчиков
                      if (['fps_total', 'fps_current', 'entity', 'network_in', 'network_out', 'uptime'].indexOf(stateKeyNested1) === -1) {
                        // Устанавливаем значения
                        vue.set(
                            state[stateKey],
                            stateKeyNested1,
                            data[dataKey][dataKeyNested1] !== null
                                ? data[dataKey][dataKeyNested1]
                                : undefined
                        )
                      }
                    } else {
                      // Устанавливаем значение ключа хранилища
                      vue.set(
                          state[stateKey],
                          stateKeyNested1,
                          data[dataKey][dataKeyNested1] !== null
                              ? data[dataKey][dataKeyNested1]
                              : undefined
                      )
                    }
                  }
                }
              })
            })
          } else {
            // Устанавливаем значение ключа хранилища
            vue.set(state, stateKey, data[dataKey] !== null ? data[dataKey] : undefined)
          }
        }
      })
    })
  },
  /**
   * Метод сбрасывает значения хранилища
   * @param state хранилище
   */
  RESET: state => {
    // Цикл по ключам объекта хранилища
    Object.keys(state).forEach(key => {
      // Если ключ объекта хранилища является объетом
      if (
          typeof state[key] !== 'undefined' &&
          state[key] instanceof Object
      ) {
        // Цикл по ключам вложенного объекта 1 уровня
        Object.keys(state[key]).forEach(nestedKey1 => {
          // Если ключ вложенного объекта 1 уровня является объектом
          if (
              typeof state[key][nestedKey1] !== 'undefined' &&
              state[key][nestedKey1] instanceof Object
          ) {
            // Цикл по ключам вложенного объекта 2 уровня
            Object.keys(state[key][nestedKey1]).forEach(nestedKey2 => {
              // Если значение ключа не является неопределенным
              if (typeof state[key][nestedKey1][nestedKey2] !== 'undefined') {
                state[key][nestedKey1][nestedKey2] = undefined
              }
            })
          } else {
            // Если значение ключа не является неопределенным
            if (typeof state[key][nestedKey1] !== 'undefined') state[key][nestedKey1] = undefined
          }
        })
      } else {
        // Если значение ключа не является неопределенным
        if (typeof state[key] !== 'undefined') state[key] = undefined
      }
    })
  },

  SET_PLAYERSLIST (state, list) {
    state.query.players.list = list
  },

  SET_COMMENT (state, comment) {
    state.comment = comment
  }

}
