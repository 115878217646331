import Axios from 'axios'
import Querystring from 'querystring'

export default Axios.create({
  // Если разработка - обращаемся на hostingark.ru, иначе на my.survivalhsot.org
  baseURL: process.env.NODE_ENV === 'production'
    ? 'https://antiddos.adman.com/api-v1/'
    : 'https://antiddos.adman.com/api-v1/',
  // Если разработка - отправляем куки, иначе не отправляем
  withCredentials: process.env.NODE_ENV !== 'production',
  transformRequest: [
    data => Querystring.stringify(data)
  ]
})
