/* eslint-disable no-undef */
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'

import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import store from '@/state/store'

// Font Awesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
  faArrowLeft,
  faBars,
  faCopy,
  faFile,
  faFolder,
  faPlus,
  faRedo,
  faUnlock
} from '@fortawesome/free-solid-svg-icons'

import App from './App.vue'
// As a plugin
import VueMask from 'v-mask'
import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from 'vue-easy-lightbox'
import {initFirebaseBackend} from './helpers/firebase/authUtils';

import {configureFakeBackend} from './helpers/fakebackend/fake-backend';
import '@/assets/scss/app.scss'
import VueNotifications from 'vue-notifications'
import VueNotificationsOptions from './modules/Notifications'

import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/mode/javascript/javascript.js'

import Editor from '@tinymce/tinymce-vue'

import vue_chartist from "vue-chartist";

Vue.config.productionTip = false

// Font Awesome
library.add(faFile, faFolder, faArrowLeft, faBars, faUnlock, faPlus, faRedo, faCopy)
Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.use(Lightbox)
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
        libraries: "places"
    },
    installComponents: true
});

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    initFirebaseBackend(firebaseConfig);
} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
    configureFakeBackend();
}

Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);

Vue.use(vue_chartist);
Vue.use(VueMask);

Vue.component('editor', Editor)

Vue.use(VueCodemirror)
Vue.use(VueNotifications, VueNotificationsOptions)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
