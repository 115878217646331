export const state = {
  product: null,
  game: null,
  group: null
}

export const mutations = {
  SET_PRODUCT (state, product) {
    state.product = product
  },
  SET_GAME (state, game) {
    state.game = game
  },
  SET_GROUP (state, group) {
    state.group = group
  }
}

export const getters = {
  GET_PRODUCT (state) {
    return state.product
  },
  GET_GAME (state) {
    return state.game
  },
  GET_GROUP (state) {
    return state.group
  }
}
