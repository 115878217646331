export const state = {
  locales: ['ru', 'en'],
  locale: 'ru'
}

export const mutations = {
  SET (state, locale) {
    if (state.locales.indexOf(locale) !== -1) {
      state.locale = locale
    }
  }
}
