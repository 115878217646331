import store from '@/state/store'

export const state = {
    // Экземпляр сокета
    instance: null,
    // Признак "Подключен"
    is_connected: undefined,
    // Список ошибок
    error_list: [],
    memoryGb: undefined,
    cpuQuotaLoadPercent: undefined,
    fps: undefined,
    ftp_files: [],
    ftp_current_dir: '',
    storageCurrent: null,
    fileUploadUrl: null,
    fileUploadMethod: null,
    fileUploadTransferEncoding: null,
    fileUploadErrorMessage: null,
    fileDownloadUrl: null,
}

export const mutations = {
    CONNECT: (state, url) => {
        if (!url) return

        // Если сокет уже подключен
        if (
            state.instance &&
            state.instance instanceof WebSocket &&
            state.is_connected
        ) return

        try {
            state.instance = new WebSocket(url)
        } catch (error) {
            // Прерываем выполнение
            return console.log(
                '%c[Vuex::wss metrics]: не удалось создать сокет: ' + error.message,
                'background: #222; color: #ed1255'
            )
        }

        if (state.instance instanceof WebSocket) {
            console.log(
                '%c[Vuex::wss metrics]: экземпляр сокета создан успешно',
                'background: #222; color: #bada55'
            )
            state.instance.onopen = () => {
                store.commit('wssmetrics/ON_OPEN')
            }
            state.instance.onclose = () => {
                store.commit('wssmetrics/CLOSE')
            }
            state.instance.onerror = error => {
                store.commit('wssmetrics/ON_ERROR', error)
            }
            state.instance.onmessage = message => {
                store.commit('wssmetrics/ON_MESSAGE', message.data)
            }
        }
    },
    CLOSE: state => {
        console.log(
            '%c[Vuex::wss metrics]: отключение сокета',
            'background: #222; color: #bada55'
        )
        Object.keys(state).forEach(key => {
            // Если WebSocket
            if (typeof state[key] !== 'undefined' && state[key] instanceof WebSocket) {
                state[key].close()
                state[key] = undefined
            }
            // Если массив
            if (typeof state[key] !== 'undefined' && state[key] instanceof Object) state[key] = []
            // Иначе
            else if (typeof state[key] !== 'undefined') state[key] = undefined
        })
        console.log(
            '%c[Vuex::wss metrics]: сокет успешно отключен',
            'background: #222; color: #bada55'
        )
    },
    ON_OPEN: state => {
        console.log(
            '%c[Vuex::wss metrics]: подключение успешно установлено',
            'background: #222; color: #bada55'
        )
        state.is_connected = true

        state.instance.send("##listen *")
        state.instance.send("$#dir")
    },
    ON_ERROR: (state, error) => {
        console.log(
            '%c[Vuex::wss metrics]: ошибка сокета: ' + error.message,
            'background: #222; color: #ed1255'
        )
        state.error_list.push(error)
    },
    ON_MESSAGE: (state, message) => {
        if (!message) return;
        try {
            message = JSON.parse(message)
        } catch (error) {
            console.error(
                `%c[Vuex::wss metricts]: не удалось конвертировать в JSON: ${message}`,
                'background: #222; color: #bada55'
            )
            return;
        }

        if (!message.field) {
            if (message.message === 'mkdir') {
                state.instance.send('$#dir');
                return;
            }
            if (message.message === 'dl') {
                state.fileDownloadUrl = message.downloadMethods.get.url;
                return;
            } else state.fileDownloadUrl = null;
            if (message.message === 'cd' || message.message === 'del') {
                state.instance.send('$#dir');
                return;
            }
            if (message.message === 'ul') {
                if (message.error) {
                    state.fileUploadErrorMessage = message.error.message;
                    return;
                }
                state.fileUploadUrl = message.methods.post.url;
                state.fileUploadMethod = message.methods.post.method;
                state.fileUploadTransferEncoding = message.methods.post.contentTransferEncoding;
                return;
            }
            if (message.message === 'dir') {
                if (message.contents.length === 1 && message.contents[0][1] === '$Root') {
                    state.instance.send('$#cd $Root');
                } else state.storageCurrent = message;
            }
            if (message.message === 'with') {
                if (message.command === 'view') {
                    window.open(message.methods.get.url, "_blank");
                }
            }
            if (message.message !== 'with' && message.command !== 'view') {
                state.fileUploadUrl = null;
                state.fileUploadMethod = null;
                state.fileUploadTransferEncoding = null;
            }
            if (message.message === 'with' && message.command === 'del') {
                state.instance.send('$#dir');
            }
        } else {
            if (message.field === '#loads') {
                state.memoryGb = message.data.memoryGb
                state.cpuQuotaLoadPercent = message.data.cpuQuotaLoadPercent
            } else if (message.field === 'averageServerFPS') {
                state.fps = message.data
            }
        }
    },
    SEND_MESSAGE: (state, message) => {
        if (!state.is_connected) {
            console.error('%c[Vuex::wss metricts]: сокет не подключен', 'background: #222; color: #bada55')
            return;
        }
        state.instance.send(message)
    },
    RESET_FILE_UPLOAD_ERROR: (state) => {
        state.fileUploadErrorMessage = null;
    }
}
