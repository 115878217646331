export const state = {
  referrer: '',
  redirect: ''
}
export const mutations = {
  SET_REFERRER (state, referrer) {
    state.referrer = referrer
  },
  SET_REDIRECT (state, redirect) {
    state.redirect = redirect
  }
}
