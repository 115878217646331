import store from '@/state/store'

export const state = {
  // Экземпляр сокета
  instance: undefined,
  // Признак "Подключен"
  is_connected: undefined,
  // Список ошибок
  error_list: [],
  // Список сообщений консоли
  console_list: [],
  // Список сообщений чата
  chat_list: []
}

export const mutations = {
  CONNECT: state => {
    // Если сервер не онлайн
    if (!store.state.server.query.is_online) return

    // Если сокет уже подключен
    if (
        state.instance &&
        state.instance instanceof WebSocket &&
        state.is_connected
    ) return

    try {
      // Если Unturned
      if (store.state.server.stid === 304930) {
        console.log(
            '%c[Vuex::wss]: игровой сервер Unturned',
            'background: #222; color: #bada55'
        )

        state.instance = new WebSocket(
            'wss://wss.survivalhost.org:6565/unturned?' +
            `host=${store.state.server.ip}&` +
            `port=${store.state.server.port}&` +
            `pass=` + encodeURIComponent(store.state.server.password_rcon)
        )
      }

      // Если 7d2d
      if (store.state.server.stid === 294420) {
        console.log(
            '%c[Vuex::wss]: игровой сервер 7D2D',
            'background: #222; color: #bada55'
        )

        state.instance = new WebSocket(
            'wss://wss.survivalhost.org:6565/7d2d?' +
            `host=${store.state.server.ip}&` +
            `port=${store.state.server.port_rcon}&` +
            `pass=` + encodeURIComponent(store.state.server.password_rcon)
        )
      }

      // Если RUST
      if (store.state.server.stid === 258550) {
        console.log(
            '%c[Vuex::wss]: игровой сервер RUST',
            'background: #222; color: #bada55'
        )

        state.instance = new WebSocket(
            'wss://wss.survivalhost.org:6035/rust/?' +
            `ip=${store.state.server.ip}&` +
            `port=${store.state.server.port_rcon}&` +
            `pass=` + encodeURIComponent(store.state.server.password_rcon)
        )
      }
    } catch (error) {
      // Прерываем выполнение
      return console.log(
          '%c[Vuex::wss]: не удалось создать сокет: ' + error.message,
          'background: #222; color: #ed1255'
      )
    }

    if (state.instance instanceof WebSocket) {
      console.log(
          '%c[Vuex::wss]: экземпляр сокета создан успешно',
          'background: #222; color: #bada55'
      )
      state.instance.onopen = () => {
        store.commit('wss/ON_OPEN')
      }
      state.instance.onclose = () => {
        store.commit('wss/CLOSE')
      }
      state.instance.onerror = error => {
        store.commit('wss/ON_ERROR', error)
      }
      state.instance.onmessage = message => {
        store.commit('wss/ON_MESSAGE', message.data)
      }
    }
  },
  CLOSE: state => {
    console.log(
        '%c[Vuex::wss]: отключение сокета',
        'background: #222; color: #bada55'
    )
    Object.keys(state).forEach(key => {
      // Если WebSocket
      if (typeof state[key] !== 'undefined' && state[key] instanceof WebSocket) {
        state[key].close()
        state[key] = undefined
      }
      // Если массив
      if (typeof state[key] !== 'undefined' && state[key] instanceof Object) state[key] = []
      // Иначе
      else if (typeof state[key] !== 'undefined') state[key] = undefined
    })
    console.log(
        '%c[Vuex::wss]: сокет успешно отключен',
        'background: #222; color: #bada55'
    )
  },
  ON_OPEN: state => {
    console.log(
        '%c[Vuex::wss]: подключение успешно установлено',
        'background: #222; color: #bada55'
    )
    state.is_connected = true

    // Если RUST - получаем историю консоли
    if (store.state.server.stid === 258550) {
      state.instance.send(JSON.stringify({
        Identifier: 1,
        Message: 'console.tail 128',
        Name: 'WebRcon'
      }))
    }
  },
  ON_ERROR: (state, error) => {
    console.log(
        '%c[Vuex::wss]: ошибка сокета: ' + error.message,
        'background: #222; color: #ed1255'
    )
    state.error_list.push(error)
  },
  ON_MESSAGE: (state, message) => {
    console.log(
        '%c[Vuex::wss]: сообщение от сокета',
        'background: #222; color: #bada55'
    )

    // Если Unturned
    if (store.state.server.stid === 304930) {
      console.log(
          '%c[Vuex::wss]: игровой сервер Unturned',
          'background: #222; color: #bada55'
      )

      console.log(
          '%c[Vuex::wss]: определение типа сообщения',
          'background: #222; color: #bada55'
      )

      // Если сообщение консоли
      if (
          !message.startsWith('[Chat]') &&
          !message.startsWith('[World]') &&
          !message.startsWith('[Group]') &&
          !message.startsWith('[Area]')
      ) {
        state.console_list.push({Time: new Date().getTime() / 1000, Message: message})
      // Если сообщение чата
      } else {
        state.chat_list.push({Time: new Date().getTime() / 1000, Message: message})
      }
    }

    // Если 7D2D
    if (store.state.server.stid === 294420) {
      console.log(
          '%c[Vuex::wss]: игровой сервер 7D2D',
          'background: #222; color: #bada55'
      )
      state.console_list.push({Time: new Date().getTime() / 1000, Message: message})
    }

    // Если RUST
    if (store.state.server.stid === 258550) {
      console.log(
          '%c[Vuex::wss]: игровой сервер RUST',
          'background: #222; color: #bada55'
      )

      console.log(
          '%c[Vuex::wss]: попытка преобразования строки в JSON',
          'background: #222; color: #bada55'
      )

      // Попытка преобразования строки в JSON
      try {
        message = JSON.parse(message)
      } catch (error) {
        // Прерываем выполнение
        return console.log(
            '%c[Vuex::wss]: не удаось преобразовать сообщение сокета в JSON: ' + message + ' ' + error.message,
            'background: #222; color: #ed1255'
        )
      }

      console.log(
          '%c[Vuex::wss]: определение типа сообщения',
          'background: #222; color: #bada55'
      )

      // События
      if (message.Identifier === 0 && message.Message) {
        // Если сообщение консоли
        message.Time = new Date().getTime() / 1000
        if (message.Type === 'Generic') {
          message.Type = 'Log'
        }
        if (!message.Message.startsWith('[CHAT]') && !message.Message.startsWith('[Better Chat]') && !message.Message.startsWith('[TEAM CHAT]') && !message.Message.startsWith('[IQChat]')) state.console_list.push(message)
        else state.chat_list.push(message)
      }

      // История событий
      if (message.Identifier === 1) {
        console.log(
            '%c[Vuex::wss]: попытка преобразования строки в JSON',
            'background: #222; color: #bada55'
        )

        try {
          message = JSON.parse(message.Message)
        } catch (error) {
          return console.log(
              '%c[Vuex::wss]: не удаось преобразовать сообщение сокета в JSON: ' + message + ' ' + error.message,
              'background: #222; color: #ed1255'
          )
        }
        message.forEach(function (item) {
          if (item.Type === 'Generic') {
            item.Type = 'Log'
          }
          if (!item.Message.startsWith('[CHAT]') && !item.Message.startsWith('[Better Chat]') && !item.Message.startsWith('[TEAM CHAT]') && !item.Message.startsWith('[IQChat]')) state.console_list.push(item)
          else state.chat_list.push(item)
        })

        console.log(message)
      }

      // Информация о игровом сервере
      if (message.Identifier === 2) {
        console.log(
            '%c[Vuex::wss]: попытка преобразования строки в JSON',
            'background: #222; color: #bada55'
        )

        try {
          message = JSON.parse(message.Message)
        } catch (error) {
          return console.log(
              '%c[Vuex::wss]: не удаось преобразовать сообщение сокета в JSON: ' + message + ' ' + error.message,
              'background: #222; color: #ed1255'
          )
        }

        store.state.server.query.name = message.Hostname
        // store.state.server.query.map = message.Map
        store.state.server.uptime = message.Uptime
        store.state.server.query.entity = message.EntityCount
        store.state.server.query.fps_total = message.Framerate
        store.state.server.query.fps_current = message.Framerate
        store.state.server.query.network_in = message.NetworkIn
        store.state.server.query.network_out = message.NetworkOut
        store.state.server.query.players.join = message.Joining
        store.state.server.query.players.queue = message.Queued
        store.state.server.query.players.active = message.Players
        store.state.server.query.players.total = message.MaxPlayers
      }

      // Список игроков
      if (message.Identifier === 3) {
        console.log(
            '%c[Vuex::wss]: попытка преобразования строки в JSON',
            'background: #222; color: #bada55'
        )

        try {
          message = JSON.parse(message.Message)
        } catch (error) {
          return console.log(
              '%c[Vuex::wss]: не удаось преобразовать сообщение сокета в JSON: ' + message + ' ' + error.message,
              'background: #222; color: #ed1255'
          )
        }

        store.state.server.query.players.list = message
            .map(player => {
              return {
                steam_id: player.SteamID,
                name: player.DisplayName,
                ping: player.Ping,
                ip: player.Address.split(':')[0]
              }
            })
      }
    }
  },
  PUSH_TO_CONSOLE_LIST: (state, data) => {
    state.console_list.push({Type: data.Type, Time: data.Time, Message: data.Message})
  },
  PUSH_TO_CHAT_LIST: (state, data) => {
    state.chat_list.push({Time: data.Time, Message: data.Message})
  },
}
