export const state = {
  cash: 0.0,
  referral_cash: 0.0,
  required_cash: 0.0
}

export const mutations = {
  SET (state, data) {
    state.cash = parseFloat(data.cash)
    state.referral_cash = parseFloat(data.referral_cash)
  },
  SET_CASH (state, cash) {
    state.cash = parseFloat(cash)
  },
  SET_REQUIRED_CASH (state, requiredCash) {
    state.required_cash = parseFloat(requiredCash)
  }
}
